import React, { useContext, useState, useEffect } from "react";
import { auth } from "../firebase";
import {
  signInWithEmailAndPassword,
  signOut,
  sendPasswordResetEmail,
  onAuthStateChanged,
  updateEmail,
  updatePassword,
} from "firebase/auth";

const AuthContext = React.createContext();

export function useAuth()
{
  return useContext(AuthContext);
}

export function AuthProvider({ children })
{
  const [currentUser, setCurrentUser] = useState();
  const [loading, setLoading] = useState(true);

  function login(email, password)
  {
    return signInWithEmailAndPassword(auth, email, password);
  }

  function logout()
  {
    return signOut(auth);
  }

  function resetPassword(email)
  {
    return sendPasswordResetEmail(auth, email);
  }

  function localUpdateEmail(email)
  {
    return updateEmail(currentUser, email);
  }

  function localUpdatePassword(password)
  {
    return updatePassword(currentUser, password);
  }

  useEffect(() =>
  {
    const unsubscribe = onAuthStateChanged(auth, (user) =>
    {
      setCurrentUser(user);
      setLoading(false);
    });

    return unsubscribe;
  }, []);

  const value =
      {
        currentUser,
        login,
        logout,
        resetPassword,
        localUpdateEmail,
        localUpdatePassword,
      };

  return (
      <AuthContext.Provider value={value}>
        {!loading && children}
      </AuthContext.Provider>
  );
}